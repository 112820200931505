import React from 'react';
import './App.css';
import './spinner.css';
const config = require('./config');
const param = require('ua-parser-js');
const parser = new param.UAParser();
/** @type {Stripe} */
let stripe;
/** @type {HTMLElement} */
let stripePaymentElement;

/**
 *
 * @param {Amount} amount
 */
function formatAmount(amount) {
	return `${amount.currency || '£'}${amount.value.toFixed(2)}`;
}

/**
 * @param {object} options stripe.paymentRequest options (currency, amount, etc)
 */
function initCardPayments(options) {
	const style = {
		base: {
			color: 'currentColor',
			fontSize: '16px',
			'::placeholder': {
				color: '#aaa'
			}
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	};
	const elements = stripe.elements();
	// Create an instance of the card Element.
	const card = (stripePaymentElement = elements.create('card', { style }));
	// Add an instance of the card Element into the `card-element` <div>.
	card.mount('#card-element');
	// Handle real-time validation errors from the card Element.
	card.addEventListener(
		'change',
		/**
		 * @param {{error: Error}} event
		 */
		function(event) {
			const displayError = document.getElementById('card-errors');
			displayError.textContent = event.error ? event.error.message : '';
		}
	);

	// create am object representing a PaymentRequest for manual card entries.
	// - this has methods that match the stripe PaymentRequest instance
	// so we can use a common interface for both browser and card payments.
	const paymentRequest = {
		/** @type {{[x:string]: (e:any) => void}} */
		_events: {},
		card,
		options,
		/**
		 *
		 * @param {string} event
		 * @param {(e:any) => void} handler
		 */
		on(event, handler) {
			this._events[event] = handler;
		},
		/**
		 *
		 * @param {string} event
		 * @param {*} arg
		 */
		trigger(event, arg) {
			const fn = this._events[event];
			typeof fn === 'function' && setTimeout(() => fn(arg), 0);
		}
	};

	const card_form = document.getElementById('card-payment-form');
	card_form.style.display = '';

	return paymentRequest;
}

/**
 * @param {*} props
 */
function CardPaymentForm(props) {
	return (
		<div id='card-payment-form' style={{ display: 'none' }}>
			<form
				id='payment-form'
				style={styles.paymentForm}
				onSubmit={e => {
					e.preventDefault();
					props.onSubmit(e);
				}}
			>
				<div style={styles.personDetails}>
					<div
						className='personalinput'
						style={{ width: '50%', marginRight: '.5em' }}
					>
						<input
							id='card-name'
							type='text'
							placeholder='Name'
							aria-label='Name'
							disabled={props.paymentInProgress}
						/>
					</div>
					<div
						className='personalinput'
						style={{ width: '50%', marginLeft: '.5em' }}
					>
						<input
							id='card-email'
							type='email'
							placeholder='Email address'
							aria-label='Email address'
							disabled={props.paymentInProgress}
						/>
					</div>
				</div>
				<div className='form-row'>
					<div id='card-element' style={styles.stripeCard}></div>
					<div id='card-errors' style={styles.cardErrors} role='alert' />
				</div>
				{!props.paymentInProgress && (
					<button id='csbtn' style={styles.cardSubmitButton} type='submit'>
						{'Pay now'}
					</button>
				)}
			</form>
		</div>
	);
}

function AnimatedTick() {
	// draws a tick and animates a circle around it
	return (
		<div style={{ fill: '#2bab31', height: '5em', margin: '2em auto' }}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				height='100%'
				viewBox='0 0 480 480'
			>
				<circle
					cx='240'
					cy='240'
					r='230'
					style={{
						stroke: '#2bab31',
						strokeWidth: '1em',
						fill: '#00000000',
						strokeDasharray: '0 1500 1500',
						animation: 'drawcircle .7s linear .2s 1 normal forwards'
					}}
				/>
				<path d='m346.34375 154.34375-154.34375 154.34375-58.34375-58.34375c-3.140625-3.03125-8.128906-2.988281-11.214844.097656-3.085937 3.085938-3.128906 8.074219-.097656 11.214844l64 64c3.125 3.121094 8.1875 3.121094 11.3125 0l160-160c3.03125-3.140625 2.988281-8.128906-.097656-11.214844-3.085938-3.085937-8.074219-3.128906-11.214844-.097656zm0 0' />
			</svg>
		</div>
	);
}

/**
 * @param {object} options stripe.paymentRequest options (currency, amount, etc)
 * @returns {Promise<StripePaymentRequest>}
 */
function initPaymentButton(options) {
	let paymentRequest = stripe.paymentRequest(options);

	const elements = stripe.elements();
	const prButton = elements.create('paymentRequestButton', {
		paymentRequest
	});

	// Check the availability of the Payment Request API first.
	return paymentRequest.canMakePayment().then(browserPay => {
		if (browserPay) {
			// load the Apple/Google Pay button into the view
			prButton.mount('#payment-request-button');
			stripePaymentElement = prButton;
		} else {
			console.log('No browser payment available');
			// show the manual card entry form
			paymentRequest = initCardPayments(options);
		}

		return paymentRequest;
	});
}

/**
 * Call the backend API to request a PaymentIntent secret
 * @param {PaymentInfo} paymentInfo payment page details
 * @param {object} payment stripe payment data (varies depending upon payment type)
 * @returns {Promise<ChargeResponse>}
 */
function submitChargeRequest(paymentInfo, payment) {
	let paymentIntentResponse;
	const now = new Date();
	const chargeRequest = {
		account: paymentInfo.account,
		clienttime: now.toString(),
		clientutc: now.getTime(),
		source: 'chargeqr',
		payment,
		amount: paymentInfo.amount,
		currencyCode: paymentInfo.currencyCode,
		mode: paymentInfo.mode,
		stripeAccount: paymentInfo.stripeAccount || null,
		yaycodeID: paymentInfo.yaycodeID || null
	};

	return fetch('/charge', {
		method: 'POST',
		body: JSON.stringify(chargeRequest),
		headers: { 'content-type': 'application/json' }
	})
		.then(res => res.json())
		.then(
			/** @param {{transactionID: string, clientSecret: string, livemode: boolean, error}} res */
			res => {
				if (res.error) throw new Error(res.error.message);
				paymentIntentResponse = res;
				if (paymentInfo.mode !== 'live') {
					// simulated payment - don't attempt to confirm payment
					return {};
				}
				// perform the actual charge
				return stripe.handleCardPayment(res.clientSecret, {
					payment_method: payment.paymentMethod.id
				});
			}
		)
		.then(
			/** @param {{ paymentIntent?: stripe.PaymentIntent, error? }} res */
			res => {
				if (res.error) throw new Error(res.error.message);
				return {
					id: paymentIntentResponse.transactionID,
					orderNum: paymentIntentResponse.orderNum
				};
			}
		);
}

/**
 *
 */

class App extends React.Component {
	state = {
		description: '',
		companyName: '',
		companyImageURL: '',
		amount: {
			currency: '£',
			value: 0
		},
		errorMessage: '',
		/**
		 * @type {'inprogress'|'complete'|null}
		 */
		paymentState: null,
		/** 'live'|'test' */
		paymentMode: 'test',
		/** @type {string|null} */
		paymentError: null,
		yaycodeID: ''
	};

	componentDidMount() {
		// the main configurable data for the page comes from the p=.. search parameter in the url
		const urlparams = new URLSearchParams(window.location.search);
		/** @type PaymentInfo */
		let paymentInfo = {
			account: '',
			amount: 0,
			companyName: '',
			currency: '£',
			currencyCode: 'gbp',
			description: '',
			mode: 'test',
			stripeAccount: '',
			yaycodeID: ''
		};
		let urldata;
		// in production we pass the data via base64-encoded JSON
		if (urlparams.has('p')) {
			try {
				urldata = JSON.parse(atob(urlparams.get('p')));
				urldata = JSON.parse(
					decodeURIComponent(escape(atob(urlparams.get('p'))))
				);
			} catch (err) {
				this.setState({ errorMessage: 'Payment information is not valid' });
				return;
			}
		} else {
			// for testing, we allow individual parameters
			urldata = {
				account: urlparams.get('account') || '',
				companyName: urlparams.get('companyName') || '',
				description: urlparams.get('description') || '',
				imageName: urlparams.get('imageName') || '',
				amount: parseFloat(urlparams.get('amount')) || 0,
				currency: urlparams.get('currency') || '£',
				currencyCode: urlparams.get('currencyCode') || 'gbp',
				stripeAccount: urlparams.get('stripeAccount') || '',
				mode: urlparams.get('mode') || 'test',
				yaycodeID: urlparams.get('yaycodeID') || ''
			};
		}
		Object.assign(paymentInfo, urldata);

		// we must have an account
		if (!/^[a-zA-Z\d]+$/.test(paymentInfo.account)) {
			this.setState({ errorMessage: 'Missing account in payment information' });
			return;
		}

		// we must have a company (that's not just whitespace)
		if (!/\S/.test(paymentInfo.companyName)) {
			this.setState({ errorMessage: 'Missing company in payment information' });
			return;
		}

		// don't allow anything less than 1 GBP/USD/AUD/..
		if (paymentInfo.amount < 1) {
			this.setState({
				errorMessage: 'Too small amount in payment information'
			});
			return;
		}
		// the amount must be a valid currency amount (up to 2 dp)
		if (!/^\d+\.?\d?\d?$/.test(paymentInfo.amount.toString())) {
			this.setState({ errorMessage: 'Invalid amount in payment information' });
			return;
		}

		// in live mode, we must have valid stripe parameters
		if (paymentInfo.mode === 'live' && !paymentInfo.stripeAccount) {
			this.setState({
				errorMessage: 'Missing stripeAccount in payment information'
			});
			return;
		}

		// Stripe has an issue where ApplePay does not work for Connect accounts using test keys, so
		// only use the vendor connected account when live Stripe keys are used or on non-apple devices
		const is_Apple = /apple/i.test(navigator.vendor);
		const connectVendorStripeAccount =
			paymentInfo.stripeAccount &&
			(/^pk_live/.test(config.stripePK) || !is_Apple);
		const stripeOpts = connectVendorStripeAccount
			? { stripeAccount: paymentInfo.stripeAccount }
			: undefined;
		// @ts-ignore Stripe is a global constructor created by the Stripe.js library
		stripe = global.Stripe(config.stripePK, stripeOpts);
		console.log(config.stripePK, stripeOpts)

		this.initAmount(paymentInfo);

		const companyImageURL = paymentInfo.imageName
			? `https://firebasestorage.googleapis.com/v0/b/${config.firebaseProject}.appspot.com/o/accounts%2F${paymentInfo.account}%2F${paymentInfo.imageName}?alt=media`
			: '';

		this.setState({
			description: paymentInfo.description,
			companyName: paymentInfo.companyName,
			companyImageURL,
			paymentMode: paymentInfo.mode,
			amount: {
				currency: paymentInfo.currency,
				value: paymentInfo.amount
			},
			yaycodeID: paymentInfo.yaycodeID
		});
	}

	/**
	 *
	 * @param {PaymentInfo} paymentInfo
	 */
	initAmount(paymentInfo) {
		// stripe accepts amounts in integers, using the smallest currency unit
		const amount = Math.round(paymentInfo.amount * 100);
		if (amount < 0 || !Number.isInteger(amount)) {
			console.error(`Bad amount value ${amount}`);
			return;
		}
		// stripe paymentRequest options
		const label = `${paymentInfo.companyName}${
			paymentInfo.mode === 'live' ? '' : ' (test)'
		}`;
		const options = {
			currency: paymentInfo.currencyCode.toLowerCase(),
			total: {
				label,
				amount
			},
			country: 'GB',
			// Apple Pay associates the payer name with their billing address. We don't want to
			// force an address to be specified, so we only request the email address.
			requestPayerName: false,
			requestPayerEmail: true,
			requestPayerPhone: false,
			requestShipping: false
		};
		initPaymentButton(options).then(x => {
			this.paymentRequest = x;
			this.paymentRequest.on('paymentmethod', e => {
				// called when a Stripe token has been created for the payment type.
				// the event parameter contains the token used for charging and other payer information
				this.makePayment(paymentInfo, e);
			});
		});
	}

	/**
	 *
	 * @param {*} paymentInfo
	 * @param {*} e
	 */
	makePayment(paymentInfo, e) {
		// sanity check that we are charging a correct amount
		if (typeof paymentInfo.amount !== 'number' || paymentInfo.amount <= 0) {
			return;
		}
		submitChargeRequest(paymentInfo, e).then(
			response => {
				// tell the PaymentRequest object the result of the charge
				e.complete('success');
				this.setState({
					paymentState: 'complete',
					paymentResult: response,
					receipt_email: e.payerEmail
				});
				// gaevent(page, 'purchase', {
				//   label: page.name,
				//   value: amount,
				// });
			},
			err => {
				e.complete('fail');
				this.setState({
					paymentState: null,
					paymentError: err.message
				});
			}
		);
		// set the paymentState to inprogress - this will disable
		// the inputs and buttons until the charge is completed
		this.setState({
			paymentState: 'inprogress',
			paymentError: null
		});
	}

	submitCardForm() {
		const billing_details = {};
		const name = document.getElementById('card-name').value.trim();
		const email = document.getElementById('card-email').value.trim();
		if (name) billing_details.name = name;
		if (email) billing_details.email = email;
		stripe
			.createPaymentMethod('card', stripePaymentElement, {
				billing_details
			})
			.then(result => {
				if (result.error) {
					// Inform the user if there was an error.
					const errorElement = document.getElementById('card-errors');
					errorElement.textContent = result.error.message;
					return;
				}
				// build an event that matches the browser PaymentRequest event when the user
				// triggers a payment
				const event = {
					methodName: 'card-payment',
					payerName: name,
					payerEmail: email,
					paymentMethod: result.paymentMethod,
					complete: () => {}
				};
				this.paymentRequest.trigger('paymentmethod', event);
			});
	}

	render() {
		if (this.state.errorMessage) {
			return (
				<div className='App'>
					<div className='main'>
						<div style={styles.errorMessage}>{this.state.errorMessage}</div>
					</div>
				</div>
			);
		}

		return (
			<div className='App'>
				<div className='main'>
					<div
						style={{
							background:
								this.state.companyImageURL &&
								`url(${this.state.companyImageURL}) center/contain no-repeat`,
							height: '10em',
							margin: '1em 2em 0'
						}}
					></div>
					<h1>{this.state.companyName}</h1>
					<div style={styles.contentContainer}>
						{this.state.paymentState !== 'complete' && (
							<div>
								{// if there's a description, show it alongside the amount
								this.state.description && (
									<div style={styles.amountDescContainer}>
										<div style={styles.desc}>
											{this.state.description || this.state.companyName}
										</div>
										<div style={styles.amount}>
											{formatAmount(this.state.amount)}
										</div>
									</div>
								)}
								{// if there's no description, just show the amount
								!this.state.description && (
									<div style={styles.amountContainer}>
										<div style={styles.amountCentered}>
											{formatAmount(this.state.amount)}
										</div>
									</div>
								)}
								{
									<div style={styles.paymentSection}>
										{/* payment request button is setup by Stripe after PaymentRequest.canMakePayment() returns true */}
										<div id='payment-request-button' style={styles.prb}></div>

										{/* card-payment-form is shown if PaymentRequest.canMakePayment() returns false */}
										<CardPaymentForm
											onSubmit={() => this.submitCardForm()}
											paymentState={this.state.paymentState}
										/>
									</div>
								}
								{this.state.paymentError && (
									<div style={styles.paymentErrorMessage}>
										{this.state.paymentError}
									</div>
								)}
							</div>
						)}
						{// show spinner while payment is being made
						this.state.paymentState === 'inprogress' && (
							<div
								className='lds-dual-ring'
								style={{ alignSelf: 'center', marginTop: '1em' }}
							></div>
						)}
						{this.state.paymentState === 'complete' && (
							<div>
								<div>
									<div style={styles.payResultLabelText}>Amount:</div>
									<div style={styles.payResultLabelValue}>
										{formatAmount(this.state.amount)}
									</div>
								</div>
								<div>
									<div style={styles.payResultLabelText}>Transaction ID:</div>
									<div style={styles.payResultLabelValue}>
										{this.state.paymentResult.id}
									</div>
								</div>
								<AnimatedTick />
							</div>
						)}
						{this.state.paymentMode !== 'live' && (
							<div
								style={styles.testPaymentLabel}
							>{`Test payment mode is enabled. Cards will not be charged.`}</div>
						)}
						<a
							href='https://www.zaura.com'
							id='logo'
							target='_blank'
							rel='noopener noreferrer'
						>
							<span>{`Powered\xa0by\xa0`}</span>
							<img alt='Zaura' src='/images/logo.png' />
						</a>
					</div>
				</div>
			</div>
		);
	}
}

const styles = {
	contentContainer: {
		padding: '0 1em',
		flex: 1,
		display: 'flex',
		flexFlow: 'column'
	},
	amountContainer: {
		alignSelf: 'stretch',
		border: '1px solid #ddd',
		borderRadius: '.8em',
		display: 'flex',
		flexFlow: 'row',
		fontSize: '1.5em',
		padding: '.5em 1em'
	},
	amountDescContainer: {
		alignSelf: 'stretch',
		border: '1px solid #ddd',
		borderRadius: '.8em',
		display: 'flex',
		flexFlow: 'row',
		fontSize: '1em',
		padding: '1em'
	},
	desc: {
		flexGrow: 1,
		display: 'inline-flex'
	},
	amount: {},
	amountCentered: {
		flex: 1
	},
	paymentSection: {
		display: 'flex',
		flex: 1,
		flexFlow: 'column',
		paddingTop: '2em'
	},
	paymentForm: {
		display: 'flex',
		flexFlow: 'column'
	},
	errorMessage: {
		color: '#733'
	},
	paymentErrorMessage: {
		color: '#f33',
		fontSize: '.8em',
		marginTop: '1em'
	},
	personDetails: {
		display: 'flex',
		flexFlow: 'row',
		justifyContent: 'space-between',
		marginBottom: '1em'
	},
	stripeCard: {
		borderBottom: '1px solid #888',
		padding: '0.4em 0',
		maxWidth: '30em',
		margin: '1em auto'
	},
	cardSubmitButton: {
		marginTop: '3em',
		padding: '1em'
	},
	cardErrors: {
		color: '#b00',
		marginTop: '.5em'
	},
	prb: {
		width: '15em',
		maxWidth: '100%',
		alignSelf: 'center'
	},
	payResultLabelText: {
		display: 'inline-flex',
		color: '#888'
	},
	payResultLabelValue: {
		display: 'inline-flex',
		marginLeft: '.5em'
	},
	testPaymentLabel: {
		fontSize: '.8em',
		marginTop: '1em',
		color: '#c66'
	}
};

export default App;
